import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { TimeoutableToast, Toast } from "~/types/shared";

export const useNotificationStore = defineStore({
  id: "notification-store",
  state: () => {
    return {
      toasts: [],
    } as {
      toasts: Array<TimeoutableToast>;
    };
  },
  actions: {
    notify(notification: Toast) {
      const id = uuidv4();
      const timestamp = new Date();
      timestamp.setMilliseconds(
        timestamp.getMilliseconds() + this.toasts.length,
      );
      this.toasts.push({ ...notification, timestamp, id });
    },

    removeToast(id: string) {
      const index = this.toasts.findIndex((toast) => toast.id === id);
      if (index !== -1) {
        this.toasts.splice(index, 1);
      }
    },
  },
  getters: {},
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationStore, import.meta.hot),
  );
}
